import { computed, ref, ComputedRef } from 'vue';
import { setItemToLocalStorage, getItemFromLocalStorage } from '@/utils/persistenceStorage';
import cssColorVariables from "@/assets/cssColorVariables";
import { setCSSStyles } from '@/utils/setCSSStyles';

interface IUseTheme {
  appTheme: ComputedRef<string>;
  colors: ComputedRef<Record<PropertyKey, string>>;
  toggleAppTheme: () => void;
}

/**
 * Класс для работы с цветами темы
 *
 * @return  {IUseTheme}  Публичные вычисляемые переменные и методы
 */
export function useTheme(): IUseTheme {
  // Создаем ref для хранения текущей темы
  const appThemeRef = ref<string>(getItemFromLocalStorage('appTheme') || 'light');

  /**
   * Текущая тема
   *
   * @return  {<string>} Название темы
   */
  const appTheme = computed((): string => appThemeRef.value);

  /**
   * Базовые цвета
   *
   * @return  {Record<PropertyKey, string>}  Объект с переменными цвета, 
   * которые разбиты на группы: темная и светлая темы
   */
  const baseColors = computed((): Record<PropertyKey, string> => (
    (cssColorVariables as any)[appTheme.value]
  ));

  /**
   * Переменные цвета пришедшие из конфига приложения
   *
   * @return  {Record<PropertyKey, string>}  [return description]
   */
  const customColors = computed((): Record<PropertyKey, string> => (
    {}
  ));

  /**
   * Скомпилированные и собранные переменные цвета
   *
   * @return  {Record<PropertyKey, string>} Объект цветов
   */
  const colors = computed((): Record<PropertyKey, string> => ({
    ...baseColors.value,
    ...customColors.value
  }));

  /**
   * Переключатель темы
   * 
   * @returns {void}
   */
  const toggleAppTheme = (): void => {
    const theme = appTheme.value === 'light' ? 'dark' : 'light';
    
    setItemToLocalStorage('appTheme', theme);
    appThemeRef.value = theme;
    
    setTimeout(() => {
      setCSSStyles(colors);
    }, 1000)
  }

  return {
    appTheme,
    colors,
    toggleAppTheme
  }
}
