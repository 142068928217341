import { App, Plugin } from 'vue';
import 'tinymce/tinymce';
// icons for html editor
import 'tinymce/icons/default';
// styles for html editor
import 'tinymce/themes/silver/theme';
import 'tinymce/skins/ui/oxide/skin.min.css';
// plugins for html editor
import 'tinymce/icons/default/icons';
import 'tinymce/themes/silver/theme';
import 'tinymce/plugins/advlist/plugin';
import 'tinymce/plugins/autolink/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/imagetools/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/charmap/plugin';
import 'tinymce/plugins/print/plugin';
import 'tinymce/plugins/preview/plugin';
import 'tinymce/plugins/hr/plugin';
import 'tinymce/plugins/pagebreak/plugin';
import 'tinymce/plugins/spellchecker/plugin';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/wordcount/plugin';
import 'tinymce/plugins/visualblocks/plugin';
import 'tinymce/plugins/visualchars/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/fullscreen/plugin';
import 'tinymce/plugins/insertdatetime/plugin';
import 'tinymce/plugins/media/plugin';
import 'tinymce/plugins/nonbreaking/plugin';
import 'tinymce/plugins/table/plugin';
import 'tinymce/plugins/contextmenu/plugin';
import 'tinymce/plugins/directionality/plugin';
import 'tinymce/plugins/emoticons/plugin';
import 'tinymce/plugins/template/plugin';
import 'tinymce/plugins/textcolor/plugin';
import 'tinymce/plugins/paste/plugin';
import 'tinymce/plugins/colorpicker/plugin';
import 'tinymce/plugins/textpattern/plugin';

// Localization
import '@/localization/tinyMCE/ru_RU';

// TinyMCE Plugin
const tinymcePlugin: Plugin = {
  install: (app: App, options?: any) => {
    // Настройки по умолчанию для TinyMCE
    const defaultConfig = {
      language: 'ru_RU',
      resize: false,
      selector: 'textarea',
      height: 500,
      plugins: [
        'advlist autolink link image imagetools lists charmap print preview hr anchor pagebreak spellchecker',
        'searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking',
        'table contextmenu directionality emoticons template textcolor paste textcolor colorpicker textpattern',
      ],
      toolbar1: 'table | bullist numlist | link unlink anchor image media | hr removeformat | subscript superscript | charmap | undo redo | searchreplace | visualchars visualblocks | print preview code',
      toolbar2: 'formatselect | fontselect | fontsizeselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent | forecolor backcolor',
      menubar: false,
      toolbar_items_size: 'small',
      file_picker_types: 'image',
      object_resizing: false,
    };

    // Слияние пользовательских опций с конфигурацией по умолчанию
    const editorConfig = { ...defaultConfig, ...options };

    // Регистрация конфигурации редактора глобально
    app.provide('tinymceConfig', editorConfig);

    // Также добавление в глобальные свойства
    app.config.globalProperties.$tinymceConfig = editorConfig;
  },
};

export default tinymcePlugin;
