<template>
  <sm-app-preloader v-model="isLoadingApp"></sm-app-preloader>

  <router-view />

  <sm-notify-bar></sm-notify-bar>
</template>

<script lang="ts" setup>
// Modules
import { ref } from 'vue';
import { forEach } from 'lodash';

// Composables
// import { useTheme } from '@/composables/useTheme';

// Components
import SmAppPreloader from '@/components/common/loaders/SmAppPreloader.vue';
import SmNotifyBar from '@/components/common/notify/SmNotifyBar.vue';

import { setCSSStyles } from './utils/setCSSStyles';

// Types
// import { AppPreloaderModelValue } from '@/types/components/loaders';

// Composables
// const { colors } = useTheme();

// Data

// const isLoadingApp = ref<AppPreloaderModelValue>(false);

// Methods

// const init = () => {
//   const root = document.documentElement;

//   isLoadingApp.value = true;

//   forEach(colors.value, (value, key): void => {
//     root.style.setProperty(`--${key}`, value);
//   });

//   // FIXME: Demo-вариант снятия прелоадера
//   setTimeout((): void => {
//     isLoadingApp.value = false;
//   }, 2000);
// }

// Hooks

// init();
setCSSStyles();
</script>
