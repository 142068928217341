<template>
  <nav class="sm-header-menu">
    <sm-header-menu-item
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      :is-active="isActive(item.route)"
    />
  </nav>
</template>

<script setup lang="ts">
// Vue
import { computed, defineProps, toRefs } from 'vue';

// Components
import SmHeaderMenuItem from '@/components/layouts/header/SmHeaderMenuItem.vue';

// Types
import { IHeaderMenuItemProps } from '@/types/components/headerMenu';
import { RouteLocationRaw, useRoute } from 'vue-router';
const route = useRoute();

const props = defineProps<{
  items?: IHeaderMenuItemProps[];
}>();

const { items } = toRefs(props);

const isActive = (path: RouteLocationRaw) => computed(() => route.path === path).value;

</script>

<style scoped>
.sm-header-menu {
  display: inline-flex;
  align-items: flex-end;
  margin: 0;
  padding: 0;
  list-style: none;
}
</style>
