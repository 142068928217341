<template>
  <component
    :is="itemTag"
    :to="route"
    class="sm-breadcrumb-item"
    :class="classlist"
  >
    {{ title }}
  </component>

  <span
    v-if="!isLast"
    class="sm-breadcrumb-item__separator"
  >/</span>
</template>

<script
  lang="ts"
  setup
>
// Modules
import { computed } from 'vue';

// Types
import { Class } from '@/types/common';
import { IBreadcrumbItemProps } from '@/types/components/breadcrumbs';

// Props

const {
  title,
  route,
  isLast
} = withDefaults(
  defineProps<IBreadcrumbItemProps>(),
  {
    title: '',
    route: null,
    isLast: false,
  }
);

// Computed

const itemTag = computed((): string => route ? 'router-link' : 'span');

const classlist = computed((): Class => ([
  { 'sm-breadcrumb-item--current': isLast },
  { 'sm-breadcrumb-item--no-route': !route }
]));
</script>

<style
  lang="scss"
  scoped
>
.sm-breadcrumb-item {
  @include micro;

  text-decoration: none;
  color: var(--Additional);

  @include transition(('color'));

  &:hover:not(.sm-breadcrumb-item--no-route) {
    color: var(--Primary);
  }

  &:active {
    color: var(--PrimaryPress);
  }
}

.sm-breadcrumb-item--current {
  color: var(--Body);
}

.sm-breadcrumb-item--no-route {
  cursor: default;
}

.sm-breadcrumb-item__separator {
  @include micro;

  color: var(--Additional);
}
</style>@/types/common@/types/components/breadcrumbs