/**
 * Генератор уникальной строки UUID для различных нужд 
 * 
 * @returns {string} UUID
 */
export const generateUuid = (): string => {
  let timestamp = getCurrentTimestamp();
  
  const uuid = generateUuidFromTemplate("xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx", timestamp);
  
  return uuid;
};

// Получение текущего времени
const getCurrentTimestamp= (): number => {
  return new Date().getTime();
};

// Применение шаблона и генерация случайных символов
const generateUuidFromTemplate = (template: string, initialTimestamp: number): string => {
  let timestamp = initialTimestamp;

  const replacedTemplate = template.replace(/[xy]/g, (char) => {
    const result = generateRandomHex(char, timestamp);
    timestamp = updateTimestamp(timestamp);
    return result;
  });

  return replacedTemplate;
};

// Генерация случайного символа в зависимости от текущей временной метки
const generateRandomHex = (char: string, timestamp: number): string => {
  const randomValue = Math.floor((timestamp + Math.random() * 16) % 16);
  return char === "x" ? randomValue.toString(16) : ((randomValue & 0x3) | 0x8).toString(16);
};

// Обновление временной метки
const updateTimestamp = (timestamp: number): number => {
  return Math.floor(timestamp / 16);
};

