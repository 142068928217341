<template>
  <teleport to="body">
    <div class="sm-notify-bar">
      <sm-notify
        v-for="notify in notifyList"
        :key="notify.uuid"
        v-bind="notify"
      ></sm-notify>
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import { ref, getCurrentInstance } from 'vue';
import SmNotify from './SmNotify.vue';
import { INotifyProps } from "@/types/components/notify";

const { proxy }: any = getCurrentInstance();

const notifyList = ref<INotifyProps[]>(proxy.$notifyList.value);
</script>