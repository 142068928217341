import { App, ref } from 'vue';
import { generateUuid } from "../utils/generateUuid";
import { INotifyProps } from "../types/components/notify";

const list = ref<INotifyProps[]>([]);

const destroy = (uuid: string) => {
  const index = list.value.findIndex((node) => (
    node.uuid === uuid
  ));

  if (index >= 0) {
    list.value.splice(index, 1);
  }
}

const add = (props: INotifyProps) => {
  
  const uuid = generateUuid();
  const hideDelay = props.delay || 6000;
  const delay = hideDelay + 300;

  list.value.push({
    ...props,
    uuid
  });

  setTimeout(() => {
    destroy(uuid);
  }, delay);
}

export default {
  install(app: App) {
    app.config.globalProperties.$notify = (props: INotifyProps) => {
      add(props);
    };

    app.config.globalProperties.$notifyList = list;
  },

  show: (props: INotifyProps) => add(props) 
}