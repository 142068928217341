<template>
  <teleport to="body">
    <transition
      name="sm-modal__animation"
      @before-enter="handleBeforeEnter"
      @after-enter="handleAfterEnter"
      @before-leave="handleBeforeLeave"
      @after-leave="handleAfterLeave"
    >
      <div
        v-show="modelValue"
        class="sm-modal"
        :class="classList"
        @click="handleCloseByBackdrop"
      >
        <transition name="sm-modal__animation-window">
          <div
            v-show="showModalWindow"
            class="sm-modal__window"
          >
            <header
              v-if="showHeader"
              class="sm-modal__header"
            >
              <slot name="header">
                <div class="sm-modal__header-wrap">
                  <p
                    v-if="title"
                    class="sm-modal__title"
                  >
                    {{ title }}
                  </p>
                  <sm-icon
                    v-if="showCloseIcon"
                    name="Cancel"
                    :size="24"
                    class="sm-modal__close"
                    @click="handleClose"
                  />
                </div>
              </slot>
            </header>

            <section class="sm-modal__body">
              <slot name="default"></slot>
            </section>

            <footer
              v-if="footer"
              class="sm-modal__footer"
            >
              <div class="sm-modal__footer-wrap">
                <slot name="footer"></slot>
              </div>
            </footer>
          </div>
        </transition>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts" setup>
// Modules
import { useSlots, computed, ref } from 'vue';

// Components
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';

// Types
import { IModalProps } from '@/types/components/modal';
import { Class } from '@/types/common';

// Composables

const { header, footer }: any = useSlots();

// Model

const modelValue = defineModel<boolean>();

// Props

const {
  title,
  width,
  height,
  maxWidth,
  maxHeight,
  cancebleBackdrop,
  showCloseIcon,
} = withDefaults(
  defineProps<IModalProps>(),
  {
    title: '',
    width: '402px',
    height: 'auto',
    maxWidth: 'initial',
    maxHeight: 'initial',
    cancebleBackdrop: true,
    showCloseIcon: true,
  }
);

// Emits

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'open' | 'openned' | 'close' | 'closed'): void;
}>();

// Data

const showModalWindow = ref<boolean>(false);

// Computed

const classList = computed((): Class => ([
  { 'sm-modal--closable-backdrop': cancebleBackdrop }
]));

const showHeader = computed((): boolean => (
  header || title
));

// Methods

const handleCloseByBackdrop = (): void => {
  if (!cancebleBackdrop) return;

  closeModal();
}

const handleClose = (): void => {
  closeModal();
}

// Transition methods
const handleBeforeEnter = (): void => {
  emits('open');
}
const handleAfterEnter = (): void => {
  showModalWindow.value = true;
  emits('openned');
}
const handleBeforeLeave = (): void => {
  showModalWindow.value = false;
  emits('close');
}
const handleAfterLeave = (): void => {
  emits('closed');
}

const closeModal = (): void => {
  emits('update:modelValue', false);
}
</script>

<style lang="scss">
.sm-modal {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  overflow-y: auto;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  background: linear-gradient(0deg, rgba(69, 88, 108, 0.33), rgba(69, 88, 108, 0.33));
}

.sm-modal--closable-backdrop {
  cursor: pointer;
}

.sm-modal__window {
  width: v-bind(width);
  height: v-bind(height);
  max-width: v-bind(maxWidth);
  max-height: v-bind(maxHeight);

  display: flex;
  flex-direction: column;
  gap: var(--gap);

  background-color: var(--Background);
  border-radius: var(--border-radius);
}

.sm-modal__header {
  padding: var(--padding) var(--padding) 0;
}

.sm-modal__header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.sm-modal__title {
  flex-basis: 100%;
  flex-grow: 1;

  @include h3;
  color: var(--Title);

  margin: 0;
}

.sm-modal__close {
  flex-shrink: 0;

  color: var(--Icon);

  cursor: pointer;

  @include transition(('color'));

  &:hover,
  &:active {
    color: var(--IconHoverPress);
  }
}

.sm-modal__body {
  flex-grow: 1;
  padding-inline: var(--padding);
  overflow: auto;
}

.sm-modal__footer {
  padding: 0 var(--padding) var(--padding);
}

.sm-modal__footer-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--gap-s);
}

// For root
.sm-modal__animation-enter-from,
.sm-modal__animation-leave-to {
  transition: opacity .15s;
  opacity: 0;
}

.sm-modal__animation-enter-active,
.sm-modal__animation-leader-active {
  transition: opacity .15s;
}

// For window
.sm-modal__animation-window-enter-from {
  transition: opacity .15s, transform .15s;
  opacity: 0;
  transform: translateY(-200%);
}

.sm-modal__animation-window-leave-active {
  transition: transform .15s;
  transform: translateY(200%);
}

.sm-modal__animation-window-enter-active,
.sm-modal__animation-window-leader-active {
  transition: opacity .15s, transform .15s;
}
</style>