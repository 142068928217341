<template>
  <li class="sm-profile-dropdown-menu-item" >
    <div class="sm-profile-dropdown-menu-item__content">
      <sm-icon
        class="sm-profile-dropdown-menu-item__icon"
        :name="icon"
      />

      <router-link
        v-if="route"
        :to="route"
        class="sm-profile-dropdown-menu-item__title-link"
      >
        {{ title }}
      </router-link>

      <span
        v-else
        class="sm-profile-dropdown-menu-item__title"
      >
        {{ title }}
      </span>
    </div>

    <slot></slot>
  </li>
</template>

<script
  lang="ts"
  setup
>
// Modules
import { defineProps } from 'vue';

// Components
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';

// Types
import { ISmProfileDropdownMenuItemProps } from '@/types/components/headerMenu';

const props = defineProps<ISmProfileDropdownMenuItemProps>();

const { title, icon, route } = props;

</script>

<style lang="scss">
.sm-profile-dropdown-menu-item {
  display: flex;
  justify-content: space-between;
  padding: var(--padding-s) var(--padding);

  cursor: pointer;

  @include transition(('background-color'));

  &:hover {
    background-color: var(--SelectionPrimary);
  }
}

.sm-profile-dropdown-menu-item__content {
  display: flex;
  gap: var(--gap-s);
}

.sm-profile-dropdown-menu-item__icon {
  min-width: 16px;

  color: var(--Icon);
}

</style>