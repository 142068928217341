// FIX ME
import { useTheme } from '@/composables/useTheme';
const { colors } = useTheme();
import { AppPreloaderModelValue } from '@/types/components/loaders';
import { ComputedRef } from 'vue';

let isLoadingApp: AppPreloaderModelValue = false;

export const setCSSStyles = (themeColors?: ComputedRef<Record<PropertyKey, string>>): void => {
  const root = document.documentElement;

  isLoadingApp = true;
  const mainColors = themeColors ? themeColors.value : colors.value;
  Object.entries(mainColors).forEach(([key, value]): void => {
    root.style.setProperty(`--${key}`, value as string);
  });

  // FIXME: Demo-вариант снятия прелоадера
  setTimeout((): void => {
    isLoadingApp = false;
  }, 2000);
};