<template>
  <router-link
    :to="item.route"
    :class="menuItemClasses"
    active-class="sm-header-menu-item--active"
  >
    <div class="sm-header-menu-item__content">
      {{ item.title }}
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue';
import { Class } from '@/types/common';
import { IHeaderMenuItemProps } from '@/types/components/headerMenu';

const props = defineProps<{
  item: IHeaderMenuItemProps;
  isActive: boolean;
}>();

const { item, isActive } = toRefs(props);

const menuItemClasses = computed((): Class => ({
  'sm-header-menu-item': true,
  'sm-header-menu-item--active': isActive.value,
}));
</script>

<style lang="scss">
$offsetTop: 20px;

.sm-header-menu-item {
  display: inline-flex;
  padding: $offsetTop var(--padding-s) 0;
  cursor: pointer;
  text-decoration: none;
  color: var(--Body);
}

.sm-header-menu-item__content {
  @include mini;
  position: relative;
  margin-bottom: calc($offsetTop + 3px);

  &:after {
    content: '';
    display: none;
    width: 100%;
    position: absolute;
    bottom: calc(($offsetTop + 3px) * -1);
    left: 50%;
    transform: translateX(-50%);
    border-top: 3px solid var(--Primary);
    border-radius: 100px 100px 0 0;
  }

  .sm-header-menu-item--active & {
    color: var(--Primary);

    &:after {
      display: block;
    }
  }
}
</style>
