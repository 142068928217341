<template>
  <div class="sm-breadcrumbs">
    <router-link
      to="/"
      class="sm-breadcrumbs__home-link"
    >
      <sm-icon name="home"></sm-icon>
    </router-link>

    <sm-breadcrumb-item
      v-for="(item, index) in items"
      :key="index"
      :title="item.title"
      :route="item.route"
      :is-last="isLastItem(index)"
    ></sm-breadcrumb-item>
  </div>
</template>

<script
  lang="ts"
  setup
>
// Components
import SmBreadcrumbItem from '@/components/common/SmBreadcrumbs/SmBreadcrumbItem.vue';
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';

// Types
import { IBreadcrumbsProps } from '@/types/components/breadcrumbs';

// Props

const {
  items
} = withDefaults(
  defineProps<IBreadcrumbsProps>(),
  {
    items: () => []
  }
);

// Methods

const isLastItem = (index: number): boolean => index === items.length - 1;
</script>

<style
  lang="scss"
  scoped
>
.sm-breadcrumbs {
  display: flex;
  align-items: center;
  gap: var(--gap-s);

  padding-inline: 5px;
}

.sm-breadcrumbs__home-link {
  display: inline-flex;
  align-items: center;

  color: var(--Icon);
}
</style>
@/types/components/breadcrumbs