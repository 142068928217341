<template>
  <button
    role="button"
    :type="nativeType"
    :tab-index="tabIndex"
    :aria-label="computedAriaLabel"
    :disabled="disabled"
    :title="computedTitle"
    class="sm-icon-button"
    @click="handleClick"
    @keydown.enter="handleKeyDownEnter"
    @keyup.space="handleKeyUpSpace"
  >
    <sm-icon
      class="sm-button__icon"
      :name="`${name}`"
      :size="size"
    />
  </button>
</template>

<script
  lang="ts"
  setup
>
// Modules
import { computed } from 'vue';

// Composables
import { useButton } from '@/composables/useButton';

// Types
import { ICommonEmits, IIconButtonProps } from '@/types/components/buttons';

// Components
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';

// Props
const {
  nativeType,
  tabIndex,
  ariaLabel,
  disabled,
  title,
  name,
  size,
} = withDefaults(defineProps<IIconButtonProps>(), {
  nativeType: 'button',
  tabIndex: 0,
  ariaLabel: null,
  disabled: false,
  title: null,
  name: '',
  size: '24'
});

// Emits

const emits = defineEmits<ICommonEmits>();

// Composables

const {
  computedAriaLabel,
  handleClick,
  handleKeyDownEnter,
  handleKeyUpSpace
} = useButton({
  ariaLabel
}, emits);

// Computed

const computedTitle = computed((): string => title || '');
</script>

<style lang="scss">
.sm-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;

  /* width: 38px; */
  /* height: 38px; */
  width: auto;
  height: auto;

  padding: 7px;

  border: none;
  border-radius: 16px;

  box-shadow: 0px 4px 8px 0px rgba(var(--DropdownShadow));
  box-shadow: 0px 8px 16px 0px rgba(var(--DropdownShadow2));

  background-color: var(--Background);
  color: var(--Icon);

  @include transition(('color'));

  &:not(:disabled) {
    cursor: pointer;
  }

  &:hover {
    color: var(--IconHoverPress);
  }

  &:active {
    color: var(--IconHoverPress);
  }

  &:disabled {
    color: var(--IconDisable);
  }
}
</style>