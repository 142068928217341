<template>
  <sm-icon
    name="Loader"
    :size=size
    class="sm-loader"
  ></sm-icon>
</template>

<script lang="ts" setup>
// Modules
import { computed, withDefaults, ref, toRefs } from 'vue';

// Components
import SmIcon from '@/components/common/SmIcon/SmIcon.vue';

// Types
import { ILoaderProps } from '@/types/components/loaders';

// Props
const props = withDefaults(defineProps<ILoaderProps>(), {
  size: 16
});

const { size } = toRefs(props);

// Computeds
const sizeString = computed((): string => `${props.size}px`);
</script>

<style lang="scss">
.sm-loader {
  width: v-bind('sizeString');
  height: v-bind('sizeString');
}
</style>

<style lang="scss">
.sm-loader {
  animation: anime 1.2s linear infinite;
}

@keyframes anime {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>@/types/components/loaders